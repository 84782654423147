import React from 'react';
import DashbaordPercOccupBox from './DashbaordPercOccupBox';
import DashboardNbDayRes from './DashboardNbDayRes';
import DashboardNbInOut from './DashBoardNbInOut';


export default function Dashboard(props: any) {

    return (
        <div className="container-fluid margtop dashboard"> 
            <div className="row">
                <div className="col-xl-6">
                    <DashboardNbInOut />
                </div>
                <div className="col-xl-6">
                    <DashbaordPercOccupBox />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <DashboardNbDayRes />
                </div>

            </div>
        </div>
    );
}
