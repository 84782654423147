import { NavLink } from "react-router-dom"
import { getDateStrFromTimeStamp, renderImgAnimal, renderSexeAnimal } from "../../utils/Tools"

function ProprioPetItem(props: any) {

    const openDlgEdit = async () => { 
        props.onEditPet(props.pet);
    }
    const openDlgDelete = async () => { 
        props.onDeletePet(props.pet);
    }
    return (
        <div className="row infoAnimal" >
            <div className="col-12 ">

                <div className={"card b text-center "+ (props.pet.IsDCD===true?"isDCD" :"")}>
                <div className="action-right">
                    <img src="/img/planimalin_delete.svg" alt='X' className="pointer" onClick={openDlgDelete} />
                    <img src="/img/planimalin_modify.svg" alt='E' className="pointer" onClick={openDlgEdit} />
                </div>

                <div className="card-body">
                    <div>
                        <img src={renderImgAnimal(props.pet.Type)} alt="cat" />
                        <img src={renderSexeAnimal(props.pet.Sexe)} alt="cat" />
                        {props.pet.IsDCD === true && <img src="/img/planimalin_rip.svg" alt="rip" />}
                    </div>
                    <NavLink to={'/pet/' + props.pet.Type + "/" + props.pet.Id}>
                        <span className='pointerBold' >{props.pet.Nom}</span>
                    </NavLink>

                    <p>{props.pet.Race}</p>
                    <p>Né(e) le   {getDateStrFromTimeStamp(props.pet.DateNaissance)}</p>

                </div>
            </div>
        </div>
  </div >
        );
}
export default ProprioPetItem;