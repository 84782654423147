import React, { useEffect,  useState } from 'react';
import { dataService } from '../../services/Services';
import { TypePet } from '../../common/models/type-pet';
import { MinMaxDate } from '../../common/models/min-max-date';
import moment from 'moment';
import { getMinMaxWithMonthYear } from '../../utils/Tools';

export default function PlanningHeader(props: { onChangePeriode: any,onChangeType:any }) {
    const [selectedMinMax, setSelectedMinMax] = useState(new MinMaxDate());
    const [listYear, setListYear] = useState<number[]>([]);
    const [listTypePet, setListTypePet] = useState<TypePet[]>([]);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedYear, setSelectedYear] = useState(0);
    const weekStepTS = (7 * 24 * 60 * 60);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        var restTypePet = await dataService.Configuration.getListCategorie();
        var restMinMax = await dataService.Configuration.getMinMaxDateExploitation();

        setListTypePet(restTypePet.data)
        props.onChangeType(restTypePet.data[0].Id);
        var yearMin = moment.unix(restMinMax.data.MinDate).year();
        var yearMax = moment.unix(restMinMax.data.MaxDate).year();
        for (var i = yearMin; i <= (yearMax + 2); i++)
            listYear.push(i);
        setListYear(listYear);

        var today = moment();
        setSelectedMonth(today.month() + 1);
        setSelectedYear(today.year());

        setSelectedMinMax(getMinMaxWithMonthYear(today.month() + 1,today.year()));

    }
    const callPrintPlanning = () => {
        window.print();
    }

    const previousWeek = () => {

        var minmax= new MinMaxDate();

        minmax.MinDate = selectedMinMax.MinDate-weekStepTS;
        minmax.MaxDate = selectedMinMax.MaxDate- weekStepTS;

        updateDateInfo(minmax);
    }

    const nextWeek = () => {

        var minmax= new MinMaxDate();

        minmax.MinDate = selectedMinMax.MinDate+weekStepTS;
        minmax.MaxDate = selectedMinMax.MaxDate+ weekStepTS;

        updateDateInfo(minmax);
       
    }

    const previousMonth = () => {
        let yeartmp = selectedYear;
        let monthtmp = selectedMonth - 1;
        if (monthtmp < 1) {
            monthtmp = 12
            yeartmp =(selectedYear - 1);
        }        
        updateDateInfo(getMinMaxWithMonthYear(monthtmp,yeartmp));
    }

    const nextMonth = () => {
        let yeartmp = selectedYear;
        let monthtmp = selectedMonth + 1;
        if (monthtmp > 12) {
            monthtmp = 1
            yeartmp =(selectedYear + 1);
        }

        updateDateInfo(getMinMaxWithMonthYear(monthtmp,yeartmp));
    }

    const onChangeMonth = (event:any) => {
        updateDateInfo(getMinMaxWithMonthYear(event.target.value,selectedYear));
    }

    const onChangeYear = (event:any) => {
        updateDateInfo(getMinMaxWithMonthYear(selectedMonth,event.target.value));
    }

 

    const updateDateInfo=(newMinMax :MinMaxDate)=>{
        setSelectedMonth(moment.unix(newMinMax.MaxDate).month() + 1);
        setSelectedYear(moment.unix(newMinMax.MaxDate).year());
        setSelectedMinMax(newMinMax);
        props.onChangePeriode(newMinMax)
    }

    const onSelectedTypeChange = (event: any) => {
        props.onChangeType(event.target.value);
    }
    

    return (
        <div className="row" style={{minWidth: '1500px'}}>
        <div className="col">
        <div className="d-flex justify-content-between">

            <select className="form-control s250" onChange={(event)=>onSelectedTypeChange(event)} >
                {listTypePet && listTypePet.map((item) => (<option key={item.Id} value={item.Id}>{item.Libelle}</option>))}
            </select>

            <div className="d-flex">
                <div className="d-flex">
                    <div className="cursorPointer btn_change_date" onClick={previousMonth}> <i className="fa-solid fa-angle-double-left" /></div>
                    <div className="cursorPointer btn_change_date" onClick={previousWeek}> <i className="fa-solid fa-angle-left" /></div>
                </div>
                <div >

                    <select className="form-control" onChange={onChangeMonth} value={selectedMonth}>
                        <option value="1">Janvier</option>
                        <option value="2">Février</option>
                        <option value="3">Mars</option>
                        <option value="4">Avril</option>
                        <option value="5">Mai</option>
                        <option value="6">Juin</option>
                        <option value="7">Juillet</option>
                        <option value="8">Août</option>
                        <option value="9">Septembre</option>
                        <option value="10">Octobre</option>
                        <option value="11">Novembre</option>
                        <option value="12">Décembre</option>
                    </select>
                </div>
                <div >
                    <select className="form-control" value={selectedYear} onChange={onChangeYear} >
                        {listYear && listYear.map((year) => (<option key={year} value={year}>{year}</option>))}
                    </select>
                </div>
                <div className="d-flex">

                    <div className="cursorPointer btn_change_date" onClick={nextWeek}> <i className="fa-solid fa-angle-right" /></div>
                    <div className="cursorPointer btn_change_date" onClick={nextMonth}> <i className="fa-solid fa-angle-double-right" /></div>
                </div>
            </div>
            <div >
                <div className="btn btn-primary" onClick={callPrintPlanning}>Imprimer</div>
            </div>
        </div >
        </div >
        </div >
    );

}
