import  { useEffect, useState } from 'react';
import DlgDelete from '../../common/dialog/DlgDelete';
import { Pet } from '../../common/models/pet';
import { dataService } from '../../services/Services';
import { NavLink, useNavigate } from 'react-router-dom';
import { getDateStr2FromTimeStamp, renderImgAnimal, renderSexeAnimal } from '../../utils/Tools';
import DlgPetEdit from '../../common/dialog/DlgPetEdit';

export default function PetInfo(props: {petId:number}) {
    const [petInfo, setPetInfo] = useState(new Pet())
    const [modeVue, setModeVue] = useState("display");
    let history = useNavigate();

    useEffect(() => {
        loadData();

    }, [props.petId]);

   const loadData = async () => {
    dataService.Pets.getPet(props.petId).then((response) => { setPetInfo(response.data); });
   }
    const onCloseDlg = async () => {
        setModeVue("display");
    }

    const openDlgDelete = async () => {
        setModeVue("delete");
    }

    const openDlgEdit = async () => {
        setModeVue("edit");
    }

  
    const onDelete = async () => {     
        dataService.Pets.deletePet(petInfo.Id).then((response) => {   onCloseDlg();  history('/pet');});
    }   
    
    const onValid = async (pet:Pet) => {     
        dataService.Pets.savePet(pet).then((response) => {    onCloseDlg(); loadData(); });
    }  

    return (
        <div className="card b">
        <div className="card-content ">
          <div className="card-header">
      
            <h3><span>{petInfo.Nom}</span> (
                <NavLink to={'/proprio/' + petInfo.IdProprietaire}>
                        <span className='pointerBold' >{petInfo.NomProprietaire}</span>
                    </NavLink>               
                
                )</h3>
      
      
          </div>
          <div className={`card-body ${petInfo.IsDCD? "isDCD" :""}`}> 
            <form className="form-horizontal">
      
              <div className="form-group row">
                <div className="col-md-2">
                  <div>
                    <img src={renderImgAnimal(petInfo.Type)} alt="type" />
                  </div>
                  <div className="d-flex">
                  <div>
                    <img src={renderSexeAnimal(petInfo.Sexe)} alt="sexe" />
                  </div>
                  {petInfo.IsDCD&& <div><img src="/img/planimalin_rip.svg" alt="rip" /></div>}
                </div>
                </div>
                <div className="col-md-10">
                  <div className="form-group row">
                    <div className="col-md-2">Nom</div>
                    <div className="col-md-4">
                      <strong> {petInfo.Nom}</strong>
                    </div>
                   {petInfo.HasSterilise&& <div className="col-md-6">Stérilisé</div>}
                   {!petInfo.HasSterilise&& <div className="col-md-6">Non stérilisé</div>}
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2">Date naissance</div>
                    <div className="col-md-4">
                      <strong>{getDateStr2FromTimeStamp(petInfo.DateNaissance)}</strong>
                      <strong> ({petInfo.Age} ans)</strong>
                    </div>
                    <div className="col-md-2">Identification</div>
                    <div className="col-md-4">
                      <strong> {petInfo.Identification}</strong>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2">Race</div>
                    <div className="col-md-4">
                      <strong> {petInfo.Race}</strong>
                    </div>                   
                  </div>
      
                </div>
      
              </div>
              <div className="form-group row">
                <div className="col-md-2">Robe</div>
                <div className="col-md-10">
                  <strong> {petInfo.Robe}</strong>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-2">Particularité</div>
                <div className="col-md-10">
                  <strong> {petInfo.Particularite}</strong>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-2">Traitement</div>
               {petInfo.HasTraitement&& <div className="col-md-10" >
                  <strong >{ petInfo.Traitement}</strong>
                </div>}
                {!petInfo.HasTraitement &&<div className="col-md-10" >
                  <strong >Pas de traitement</strong>
                </div>   }
              </div>
              <div className="form-group row">
                <div className="col-md-2">Provenance</div>
                <div className="col-md-10">
                  <strong>{petInfo.Provenance}</strong>
                </div>
              </div>
            </form>
            <hr className="d-print-none" />
      
            <div className="card-action clearfix">
                    <button type="button" className="btn btn-outline-danger" onClick={openDlgDelete}  >Supprimer</button>
                    <button type="button" className="btn btn-outline-primary" onClick={openDlgEdit}  >Modifier</button>
            </div>
      
      
          </div>
        </div> 
         { modeVue==="delete" && <DlgDelete onClose={onCloseDlg}   libelle={petInfo.Nom} onDelete={onDelete}/> }
            { modeVue==="edit" && <DlgPetEdit onClose={onCloseDlg}   pet={petInfo} onValid={onValid} /> }
      </div>

    );
}