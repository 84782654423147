import { useContext, useEffect, useState } from "react";
import { Periode } from "../../common/models/periode";
import { dataService } from "../../services/Services";
import { ConfigContext } from "../../context/ConfigContext";
import { ConfigContextType } from "../../common/models/config";
import { Dialog } from "../../components/Dialog";
import { Box } from "../../common/models/box";
import { NavLink } from "react-router-dom";
import { Pet } from "../../common/models/pet";
import { Tarification } from "../../common/models/tarification";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { extractDateFromTimeStamp, getDateFromTimeStamp } from "../../utils/Tools";
import TimePicker from "../../components/TimePicker";
import Select from "react-select";


function DlgPeriodeEdit(props: { periode: Periode, onClose: any, onValid: any, onDelete: any }) {

    const { config } = useContext(ConfigContext) as ConfigContextType;

    const [listPet, setListPet] = useState<Pet[]>([]);
    const [listBox, setListBox] = useState<Box[]>([]);
    const [listTarif, setListTarif] = useState<Tarification[]>([]);
    const { register, setValue, handleSubmit } = useForm();
    const [selectedDateDeb, setSelectedDateDeb] = useState(new Date());
    const [selectedDateFin, setSelectedDateFin] = useState(new Date());
    const [selectedTimeDeb, setSelectedTimeDeb] = useState(0);
    const [selectedTimeFin, setSelectedTimeFin] = useState(0);
    const [selectedOption, setSelectedOption] = useState<Pet>(new Pet());
    const [messageError, setMessageError] = useState<String[]>([]);


    useEffect(() => {
        if (props.periode !== undefined) {

            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.periode]);


    const loadData = async () => {
        var restPet = await dataService.Pets.listPet(props.periode.TypeAnimal);
        var restbox = await dataService.Boxs.listBox(props.periode.TypeAnimal);
        var resttarif = await dataService.Tarifications.listTarification(props.periode.TypeAnimal);

        setListBox(restbox.data);
        setListTarif(resttarif.data);
        setListPet(restPet.data);

        setValue("IdBox", props.periode.IdBox);
        setValue("TarifId", props.periode.TarifId);
        var dateDebTs = extractDateFromTimeStamp(props.periode.DateDeb);
        var dateFinTs = extractDateFromTimeStamp(props.periode.DateFin);
        setSelectedDateDeb(getDateFromTimeStamp(dateDebTs));
        setSelectedDateFin(getDateFromTimeStamp(dateFinTs));
        setSelectedTimeDeb(props.periode.DateDeb - dateDebTs);
        setSelectedTimeFin(props.periode.DateFin - dateFinTs);
        if (props.periode.IdAnimal > 0) {
            var pet = restPet.data.find((x: Pet) => x.Id === props.periode.IdAnimal);
            if (pet !== undefined) {
                setSelectedOption(pet);

            }
        }
    };


    const setDateDeb = (newDate: any) => {
        if (newDate !== null) {
            setSelectedDateDeb(newDate);
        }
    }

    const setDateFin = (newDate: any) => {
        if (newDate !== null) {
            setSelectedDateFin(newDate);
        }
    }


    const handleChange = async (selectedOption: any) => {
        setSelectedOption(selectedOption);

    };

    const updateTimeDeb = async (newTime: number) => {
        setSelectedTimeDeb(newTime);
    };

    const updateTimeFin = async (newTime: number) => {
        setSelectedTimeFin(newTime);
    };


    const onSubmit = (data: any) => {
        setMessageError([]);
        var periode = props.periode;
        periode.IdBox = data.IdBox;
        periode.TarifId = data.TarifId;
        periode.Montant = data.Montant;
        periode.IdAnimal = selectedOption.Id;
        if (periode.Montant === undefined)
            periode.Montant = 0;
        periode.Acompte = data.Acompte;
        if (periode.Acompte === undefined)
            periode.Acompte = 0;
        periode.DateDeb = (selectedDateDeb.getTime()) / 1000 + selectedTimeDeb;
        periode.DateFin = (selectedDateFin.getTime()) / 1000 + selectedTimeFin;

        if (periode.IdAnimal !== undefined && periode.IdAnimal > 0 &&
            !isNaN(periode.DateDeb) && !isNaN(periode.DateFin) &&
            (periode.DateDeb < periode.DateFin)) {
            props.onValid(periode);
        } else {
            let message = new Array<string>();
            message.push("Veuillez remplir correctement tous les champs :");
            if(periode.IdAnimal === undefined || periode.IdAnimal <= 0)
            message.push(" - Animal incorrect");
            if(isNaN(periode.DateDeb) || isNaN(periode.DateFin))
            message.push(" - date incorrect");
            if(periode.DateDeb >= periode.DateFin)
            message.push(" - date de fin doit être supérieure à la date de début");

            setMessageError(message);
        }

    }

    const onCloseDlg = async () => {

        props.onClose();
    }


    const onDelete = async () => {

        props.onDelete(props.periode.Id);
    }

    return (
        <Dialog
            title="Information période"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <div onClick={onDelete} className="btn btn-outline-danger margright" >Supprimer</div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>

            <div className="dlg_periode_edit">
                <div className="form-group row">
                    <label className="col-3 col-form-label">Box</label>
                    <div className="col-7">
                        <select className="form-control" {...register("IdBox", { required: false })} defaultValue={props.periode.IdBox}>
                            {listBox && listBox.map((b) => <option key={b.Id} value={b.Id}>{b.Nom}</option>)}
                        </select>
                    </div>

                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Animal</label>
                    <div className="col-7">
                        <Select
                            classNamePrefix="react-select-header"
                            getOptionLabel={option => `${option.Nom} (${option.NomProprietaire})`}
                            value={selectedOption}
                            onChange={handleChange}
                            options={listPet}
                        />
                    </div >
                    {selectedOption && <div className="col-2">
                        <NavLink to={'/pet/' + selectedOption.Type + "/" + selectedOption.Id}>
                        <i className="fa-solid fa-paw" />
                        </NavLink>
                        <NavLink to={'/proprio/' + selectedOption.IdProprietaire}>
                        <i className="fa-solid fa-user" />
                        </NavLink>
                    </div >}
                </div >
                <div className="form-group row">
                    <label className="col-3 col-form-label d-flex align-items-center">Date d'arrivée</label>
                    <div className="col-4 d-flex align-items-center">
                        <DatePicker selected={selectedDateDeb} className="form-control" onChange={(date) => setDateDeb(date)} dateFormat="dd/MM/yyyy" />
                    </div>
                    <div className="col-4">
                        <TimePicker date={selectedTimeDeb} onTimeChange={updateTimeDeb} />
                    </div >
                </div >
                <div className="form-group row">
                    <label className="col-3 col-form-label  d-flex align-items-center ">Date de départ</label>
                    <div className="col-4 d-flex align-items-center">
                        <DatePicker selected={selectedDateFin} className="form-control" onChange={(date) => setDateFin(date)} dateFormat="dd/MM/yyyy" />

                    </div>
                    <div className="col-4">
                        <TimePicker date={selectedTimeFin} onTimeChange={updateTimeFin} />
                    </div >
                </div >
                {config.ExistFacture === true && <div className="form-group row" >
                    <label className="col-3 col-form-label ">Tarif</label>
                    <div className="col-7 ">
                        <select className="form-control"  {...register("TarifId", { required: false })} defaultValue={props.periode.TarifId} >
                            {listTarif && listTarif.map((tarif) => <option key={tarif.Id} value={tarif.Id}> {tarif.Libelle} ({tarif.Montant} €)</option>)}
                        </select >
                    </div >
                </div >
                }
                {config.ExistFacture === false && <div className="form-group row"  >
                    <label className="col-3 col-form-label ">Montant</label>
                    <div className="col-4 ">
                        <input placeholder="Montant" type="number" className="form-control" {...register("Montant", { required: false })} defaultValue={props.periode.Montant} />
                    </div>
                    <label className="col-3 col-form-label ">Acompte</label>
                    <div className="col-4">
                        <input placeholder="Acompte" type="number" className="form-control"  {...register("Acompte", { required: false })} defaultValue={props.periode.Acompte} />
                    </div>
                </div >}
                <div className="ligne">
                    <div className="text-danger">
                    {messageError && messageError.map((message,index) => <div key={index} > {message} </div>)}
                        </div>
                </div>
            </div >

        </Dialog >
    );
}
export default DlgPeriodeEdit;