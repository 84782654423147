import React, { useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { dataService } from '../../services/Services';
import { Pet } from '../../common/models/pet';
import PetInfo from './PetInfo';
import PetPeriodeList from './PetPeriodeList';
import PetInfoComplementaire from './PetInfoComplementaire';
import PetRelation from './PetRelation';
import PetVaccinList from './PetVaccinList';

const PetPage = () => {
    const [selectedPetId, setSelectedPetId] = useState(0);
    const [selectedPetType, setSelectedPetType] = useState(0);
    const params = useParams()
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [listPet, setListPet] = useState<any[]>([]);

    let history = useNavigate();

    React.useEffect(() => {

        if (params.petType !== undefined) {
            dataService.Pets.listPet(parseInt(params.petType)).then((response) => { loadPet(response.data) });
            setSelectedPetType(parseInt(params.petType));
        }
        if (params.petId !== undefined)
            setSelectedPetId(parseInt(params.petId));

    }, [params]);

    const loadPet = async (listPet: Pet[]) => {
        setListPet(listPet);
        if (params.petId !== undefined) {
            var pet = listPet.find(x => x.Id === parseInt(params.petId ? params.petId : "0"));
            if (pet !== undefined) {
                setSelectedOption(pet);
            }
        }
    }

    const handleChange = async (selectedOption: any) => {
        setSelectedOption(selectedOption);
        history('/pet/'+params.petType+'/'+selectedOption.Id);
    };


    if (selectedOption && selectedPetId > 0) {
        return (

            <div>
                <div >
                    <div >
                        <div className="filter-header d-flex justify-content-end" >
                            <Select
                                classNamePrefix="react-select-header"
                                getOptionLabel={option => `${option.Nom}`}
                                value={selectedOption}
                                onChange={handleChange}
                                options={listPet}
                            />
                         
                        </div>
                    </div>


                </div>

                <div className="container-fluid"  >
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="row">
                                <div className="col-xl-12">
                                    <PetInfo petId={selectedPetId} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <PetInfoComplementaire petId={selectedPetId}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <PetPeriodeList petId={selectedPetId}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="row">
                                <div className="col-xl-12">
                                    <PetVaccinList petId={selectedPetId} petType={selectedPetType} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <PetRelation petId={selectedPetId} typeRelation={1} petType={selectedPetType}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <PetRelation petId={selectedPetId} typeRelation={2} petType={selectedPetType}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else
        return (
            <div>
                {/* <div>Aniamal n'existe pas</div>
                <NavLink to={'/pet/0'}>
                <span className='pointer text-bold' >Retour à la liste </span>
                    
                </NavLink> */}
            </div>
        )
}
export default PetPage;