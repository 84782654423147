import React, { useEffect, useState } from 'react';
import { InfoResaMonthByYear } from '../../common/models/info-resa-month-by-year';
import { TypePet } from '../../common/models/type-pet';
import { dataService } from '../../services/Services';
import { convertMonthToString } from '../../utils/Tools';

export default function DashboardNbDayRes(props: any) {
    const [infoResaMonthByYear, setInfoResaMonthByYear] = useState(new InfoResaMonthByYear());
    const [listTypeAnimal, setListTypeAnimal] = useState<TypePet[]>([]);
    const [selectedTypeAnimal, setSelectedTypeAnimal] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        var restTypePet = await dataService.Configuration.getListCategorie();

        let defaultType = restTypePet.data[0].Id;

        setListTypeAnimal(restTypePet.data);
        loadGraph(defaultType);

    }

    const loadGraph = async (selectedType: number) => {

        var restComparatif = await dataService.Statistique.getComparatifPeriode(selectedType);

        setSelectedTypeAnimal(selectedType);
        setInfoResaMonthByYear(restComparatif.data);
    }

    const onSelectedTypeChange = (event: any) => {
        loadGraph(event.target.value);
    }

    return (
        <div className="card  b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Nombre de jours de réservation total </h3>
                </div>
                <div className="card-body">
                    <div className="contentBox">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {
                                            infoResaMonthByYear && infoResaMonthByYear.ListYear.map((year) =>
                                                <th key={year} >{year}</th>
                                            )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        infoResaMonthByYear && infoResaMonthByYear.ListMonthYear.map((infoMonth) =>
                                            <tr key={infoMonth.Month} >
                                                <td>{convertMonthToString(infoMonth.Month)}</td>
                                                {
                                                    infoMonth.PerForMonthByYear && infoMonth.PerForMonthByYear.map((infoPeriode,index) =>
                                                        <td key={index}>
                                                            {infoPeriode.NoData === 1 && <div className='infoPeriode'> {infoPeriode.NbPeriode} </div>}
                                                            {infoPeriode.Percent === 0 && infoPeriode.NoData === 0 && <div className='infoPeriode text-info'>
                                                                {infoPeriode.NbPeriode}   <i className="fa-solid fa-arrow-right" />  (-)</div>}
                                                            {infoPeriode.Percent > 0 && <div className='infoPeriode text-success'> {infoPeriode.NbPeriode}  <i className="fa-solid fa-arrow-trend-up" />  ({infoPeriode.Percent}%)</div>}
                                                            {infoPeriode.Percent < 0 && <div className='infoPeriode text-danger'> {infoPeriode.NbPeriode}  <i className="fa-solid fa-arrow-trend-down" />  ({infoPeriode.Percent}%)</div>}
                                                        </td>
                                                    )
                                                }
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr className="d-print-none" />
                    <div className="card-action clearfix">
                        <select className="form-control" onChange={(event) => onSelectedTypeChange(event)} defaultValue={selectedTypeAnimal}>
                            {
                                listTypeAnimal && listTypeAnimal.map((type) =>
                                    <option key={type.Id} value={type.Id}>{type.Libelle}</option>
                                )}
                        </select>
                    </div>
                </div>
            </div >


        </div >
    );
}
