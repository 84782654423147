export function Dialog(props: {  onClose: any;onSubmit:any; title: any; children: any; actionbutton: any; }) {

    const {  onSubmit, title, children, actionbutton } = props;
    // if (!open) {
    //     return <div></div>;
    // }
    return (
        <div className="dlg_react_background" >
            <div className="content">
                <div className="dlg_react2">
                    <div className="">
                        <div className="dlg_react_titleBar">
                            <h4>{title} </h4> 
                            {/* <div className='dlg_react_close' onClick={() => onClose()}>X</div> */}
                        </div>
                        <form onSubmit={onSubmit} >
                            <div className="dlg_react_content">
                                {children}
                            </div>
                            <div className="dlg_react_action">
                                {actionbutton}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function DialogOpen(props: { open: any; onClose: any;onSubmit:any; title: any; children: any; actionbutton: any; }) {

    const { open, onSubmit, title, children, actionbutton } = props;
    if (!open) {
        return <div></div>;
    }
    return (
        <div className="dlg_react_background" >
            <div className="content">
                <div className="dlg_react2">
                    <div className="">
                        <div className="dlg_react_titleBar">
                            <h4>{title} </h4> 
                            {/* <div className='dlg_react_close' onClick={() => onClose()}>X</div> */}
                        </div>
                        <form onSubmit={onSubmit} >
                            <div className="dlg_react_content">
                                {children}
                            </div>
                            <div className="dlg_react_action">
                                {actionbutton}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}