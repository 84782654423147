import { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom'
import { VaccineAlert } from "../../common/models/vaccine";
import { dataService } from "../../services/Services";
import { getDateStrFromTimeStamp, renderImgAnimal } from "../../utils/Tools";

function AlertVaccin(props:any) {
    const [alertVaccin, setAlertVaccin] = useState<VaccineAlert[]>([]);
    useEffect(() => {

        refreshListAlertVaccin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedDate]);

const refreshListAlertVaccin=()=>{
    dataService.Pets.listAlertVaccin(props.selectedDate).then((response) => { setAlertVaccin(response.data) });
}

    const hideAlertVaccin = (vaccinId:number) => {
       dataService.Pets.updateAlertVaccinView(vaccinId).then((response) => { refreshListAlertVaccin();});

    }
    return (
        <div className="card b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Rappel vaccins</h3>
                </div>
                <div className="card-body limit500">

                    {alertVaccin !== undefined && alertVaccin.map((item,index) => (
                        <div key={index} className='d-flex margbottom justify-content-between line'>
                            <div className='d-flex'>
                                <img src={renderImgAnimal(item.Type)} alt="cat" />
                                <div>
                                    <div className='d-flex'>
                                        <NavLink to={'/pet/' + item.Type + '/' + item.Id}>
                                            <span className='pointerBold' >
                                                {item.Nom}
                                            </span>
                                        </NavLink>
                                        <NavLink to={"/proprio/" + item.IdProprietaire}>
                                            <span className='pointerBold'>
                                                &nbsp;({item.NomProprietaire})
                                            </span>
                                        </NavLink>
                                    </div>
                                    <div className='d-flex'>
                                        <span>Vaccin&nbsp;</span>
                                        <div className="text-bold">{item.LibelleVaccin }</div>
                                        <span>&nbsp;à faire avant le &nbsp;</span>
                                        {item.DateVaccin > 0 && <span className="text-bold">{getDateStrFromTimeStamp(item.DateVaccin)}</span>}
                                        {item.DateVaccin <= 0 && <span className="text-bold" >Non défini</span>}
                                    </div>
                                </div>
                            </div>

                            <div className=" text-success pointer action" onClick={()=>hideAlertVaccin(item.AssoVaccinId)}> <i className="fa-solid fa-eye-slash" /></div>
                        </div>


                    ))}
                    {(alertVaccin === undefined || alertVaccin.length === 0) && <div >Aucun rappel</div>
                    }
                </div>
            </div>
        </div>);

};
export default AlertVaccin;
