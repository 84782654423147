import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Invoice, InvoiceItem } from '../../common/models/invoice';
import { Proprio } from '../../common/models/proprio';
import { dataService } from '../../services/Services';
import { getDateFromTimeStamp,  round2Digit } from '../../utils/Tools';
import DatePicker from "react-datepicker";
import InvoiceEditService from './InvoiceEditService';
import { SelectProprio } from '../../components/SelectProprio';
import { ResultId } from '../../common/models/result-id';

export default function InvoiceEdit(props: any) {

    const [selectedProprio, setSelectedProprio] = useState(new Proprio());
    const [selectedDateInvoice, setSelectedDateInvoice] = useState(new Date());
    const [invoiceInfo, setInvoiceInfo] = useState<null | Invoice>(null);
    const [refreshId, setRefreshId] = useState(0);
    const [totalPension, setTotalPension] = useState(0);
    const [totalAutre, setTotalAutre] = useState(0);
    let history = useNavigate();
    const params = useParams()

    React.useEffect(() => {

        loadData();

    }, [params]);


    const loadData = async () => {
        if (params.id !== undefined) {
            if (parseInt(params.id) > 0) {
                dataService.Invoices.getInvoice(parseInt(params.id)).then((response) => { computeInvoice(response.data) });
            }
            else {
                dataService.Invoices.getNumInvoice().then((response) => { newInvoice(response.data) });
            }
        }
    }

    const computeInvoice = async (invoice: Invoice) => {

        setInvoiceInfo(invoice);
        loadProprioInfo(invoice.IdProprietaire);
        setSelectedDateInvoice(getDateFromTimeStamp(invoice.PaiementDate));
        setRefreshId(refreshId + 1)
    }

    const newInvoice = async (numero: Invoice) => {
        let invoiceInfoChange = new Invoice();
        invoiceInfoChange.Id = 0;
        invoiceInfoChange.Numero = numero.Numero;
        invoiceInfoChange.IdProprietaire = 0;
        invoiceInfoChange.NomProprietaire = "";
        invoiceInfoChange.Adresse = "";
        invoiceInfoChange.Statut = 0;
        invoiceInfoChange.Date = new Date().getTime() / 1000
        invoiceInfoChange.Remise = 0;
        invoiceInfoChange.Acompte = 0;
        invoiceInfoChange.Taxe = 0;
        invoiceInfoChange.Montant = 0;
        invoiceInfoChange.Debours = 0;
        invoiceInfoChange.AcompteType = 0;
        invoiceInfoChange.ListDetail = [];
        setInvoiceInfo(invoiceInfoChange);
    }

    const listServiveUpdated = async (listService: InvoiceItem[]) => {
        computeTotalInvoice(listService);
    }

    const computeTotalInvoice = async (listService: InvoiceItem[]) => {
        if (invoiceInfo) {
            let totalPensionTmp = 0;
            let totalAutreTmp = 0;
            let invoicePensionTmp = new Array<InvoiceItem>();
            let invoiceAutreTmp = new Array<InvoiceItem>();
            listService.forEach(element => {
                if (element.Type === 0) {
                    totalPensionTmp += Math.round(element.MontantU * element.Nombre * (1 - element.Remise / 100) * (1 + element.Tva / 100) * 100) / 100;
                    invoicePensionTmp.push(element);
                } else if (element.Type === 1) {
                    totalAutreTmp += Math.round(element.MontantU * element.Nombre * (1 - element.Remise / 100) * (1 + element.Tva / 100) * 100) / 100;
                    invoiceAutreTmp.push(element);
                }
            });
            invoiceInfo.ListDetail = listService;
            setTotalPension(totalPensionTmp);
            setTotalAutre(totalAutreTmp);
            invoiceInfo.Montant = Math.round((totalPensionTmp - totalPensionTmp * invoiceInfo.Remise / 100 + totalAutreTmp - invoiceInfo.Acompte + invoiceInfo.Debours) * 100) / 100;
            setRefreshId(refreshId + 1)
        }
    }

    const updateAcompte = async (event: any) => {
        if (invoiceInfo) {
            invoiceInfo.Acompte = Number(event.target.value);
            if(invoiceInfo.AcompteType===0)
                invoiceInfo.AcompteType=2;
            refreshInvoiceInfo(invoiceInfo);
        }
    }

    const updateAcompteType = async (event: any) => {
        if (invoiceInfo) {
            invoiceInfo.AcompteType = Number(event.target.value);
            refreshInvoiceInfo(invoiceInfo);
        }
    }

    const updateDebours = async (event: any) => {
        if (invoiceInfo) {
            invoiceInfo.Debours = Number(event.target.value);
            refreshInvoiceInfo(invoiceInfo);
        }
    }
    const updateRemiseTotal = async (event: any) => {
        if (invoiceInfo) {
            invoiceInfo.Remise = Number(event.target.value);
            refreshInvoiceInfo(invoiceInfo);
        }
    }

    const refreshInvoiceInfo = async (newInvoiceInfo: Invoice) => {
        if (invoiceInfo) {
            newInvoiceInfo.Montant = Math.round((totalPension - totalPension * invoiceInfo.Remise / 100 + totalAutre - invoiceInfo.Acompte + invoiceInfo.Debours) * 100) / 100;
            setInvoiceInfo(newInvoiceInfo);
            setRefreshId(refreshId + 1)
        }
    }


    const loadProprioInfo = async (id: number) => {
        var restproprio = await dataService.Proprios.getProprio(id);
        setSelectedProprio(restproprio.data);
    }

    const onSelectProprio = async (selectedId: number) => {
        loadProprioInfo(selectedId);
    }

    const setDateInvoice = (newDate: any) => {
        if (newDate != null) {
            setSelectedDateInvoice(newDate);
        }
    }

    const validChange = async () => {
        if (invoiceInfo) {
            invoiceInfo.Date = selectedDateInvoice.getTime() / 1000;
            if (invoiceInfo.Acompte <= 0) {
                invoiceInfo.AcompteType = 0;
            }
            invoiceInfo.IdProprietaire = selectedProprio.Id;
            if (invoiceInfo.IdProprietaire > 0) {
                if (invoiceInfo.ListDetail.length > 0) {
                    if (invoiceInfo.ListDetail.findIndex(x => x.Libelle === "") === -1) {
                        if (invoiceInfo.ListDetail.findIndex(x => x.TarifId === 0 || x.TarifId === null) === -1) {
                            dataService.Invoices.saveInvoice(invoiceInfo).then((response) => { dealResult(response.data) });

                        } else {
                            alert("Veuillez choisir une tarif pour chaque service");
                        }
                    } else {
                        alert("Veuillez saisir un libellé pour chaque service");
                    }
                } else {
                    alert("Veuillez saisir au moins un service");
                }
            } else {
                alert("Veuillez choisir un propriétaire");
            }
        }
    }

    const dealResult = async (result: ResultId) => {
        if (result.CodeError === 0) {
            history('/invoice-detail/' + result.IdResult)
        } else {
            alert("Impossible de modifier la facture");
        }
    }

    return (
        invoiceInfo &&
        <div className="invoice-edit"  >
            <div>
                <div className="filter-header d-flex justify-content-end">
                    {invoiceInfo.Id === 0 && <NavLink to={'/invoice'}>
                        <div className="btn btn-outline-primary margright" >Annuler</div>
                    </NavLink>}
                    {invoiceInfo.Id > 0 && <NavLink to={'/invoice-detail/' + invoiceInfo.Id}>
                        <div className="btn btn-outline-primary margright">Annuler</div>
                    </NavLink>}
                    <div className="btn btn-outline-primary " onClick={validChange}>Valider</div>
                </div>

            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Client</h5>
                                <div className="form-group row">
                                    <div className="col-3 col-form-label">Facturer à </div>
                                    <div className="col-9">
                                        <SelectProprio onSelect={onSelectProprio} defaultProprioId={invoiceInfo.IdProprietaire} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4">
                                        <div className="text-muted">Adresse</div>
                                        <div>{selectedProprio.Adresse}</div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="text-muted">Email</div>
                                        <div>{selectedProprio.Email}</div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="text-muted">Téléphone</div>
                                        <div className='d-flex'>{selectedProprio.NumTel}
                                            {selectedProprio.NumTel !== undefined && selectedProprio.NumTel !== '' && selectedProprio.NumPortable !== '' && <div>/ </div>}
                                            {selectedProprio.NumPortable}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">

                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Facture</h5>
                                <div className="form-group row">
                                    <label className="col-3 col-form-label">Numéro</label>
                                    <div className="col-9 col-form-label">
                                        <div>{invoiceInfo.Numero}</div>
                                        {/* <!-- <input placeholder="numéro" name="numero" type="mumber" className="form-control"
                                [(ngModel)]="invoiceInfoChange.Numero" /> --> */}

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label">Date</label>
                                    <div className="col-9">
                                        <DatePicker selected={selectedDateInvoice} className="form-control" onChange={(date) => setDateInvoice(date)} dateFormat="dd/MM/yyyy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <InvoiceEditService listService={invoiceInfo.ListDetail} proprioId={selectedProprio.Id} listServiveUpdated={listServiveUpdated} />
                    </div >
                </div >

                <div className="row">

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Récapitulatif</h5>
                                <div className="form-group row">
                                    <div className="col-4 col-form-label">Montant total des pensions</div>
                                    <div className="col-8 col-form-label">
                                        {round2Digit(totalPension)}€
                                    </div>

                                </div>
                                <div className="form-group row">
                                    <div className="col-4 col-form-label">
                                        <div className='d-flex justify-content-between'>
                                            <label> Pourcentage remise sur le total des pensions</label>
                                            <label>-</label>
                                        </div>
                                    </div>
                                    <div className="col-2 col-form-label d-flex">
                                        <input placeholder="Pourcentage remise global" name="remiseg" type="mumber"
                                            onChange={(event) => updateRemiseTotal(event)} className="form-control" defaultValue={invoiceInfo.Remise} />
                                        <div className="margleft"> %</div>
                                    </div>
                                    <div className="col-6 col-form-label">
                                        (-{round2Digit(totalPension * invoiceInfo.Remise / 100)}€ )
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-4 col-form-label">Montant autre prestation</div>
                                    <div className="col-8 col-form-label">
                                        {round2Digit(totalAutre)}€
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-4 col-form-label">
                                        <div className='d-flex justify-content-between'>
                                            <label>Acompte</label>
                                            <label>-</label>
                                        </div>
                                    </label>
                                    <div className="col-2 d-flex">
                                        <input placeholder="acompte" name="acompte" type="number" className="form-control" defaultValue={invoiceInfo.Acompte}
                                            onChange={(event) => updateAcompte(event)} />
                                        <div className="margleft"> €</div>
                                    </div>
                                    {invoiceInfo.Acompte > 0 && <div className="col-2">
                                        <select className="form-control" defaultValue={invoiceInfo.AcompteType} name="acomptetype"   onChange={(event) => updateAcompteType(event)}>
                                            <option value='2'>Chèque</option>
                                            <option value='3'>Carte bleue</option>
                                            <option value='4'>Espèces</option>
                                            <option value='5'>Virement</option>
                                            <option value='6'>Paypal</option>
                                            <option value='1'>Autres</option>
                                        </select>
                                    </div>}
                                </div>
                                <div className="form-group row">
                                    <label className="col-4 col-form-label">Débours</label>
                                    <div className="col-2 d-flex">
                                        <input placeholder="debours" name="debours" type="number" className="form-control" defaultValue={invoiceInfo.Debours}
                                            onChange={(event) => updateDebours(event)} />
                                        <div className="margleft"> €</div>
                                    </div>

                                </div>
                                <div className="form-group row">
                                    <div className="col-4 col-form-label">Total Net à payer</div>
                                    <div className="col-8 col-form-label">
                                        {round2Digit(invoiceInfo.Montant)}€
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div >

                </div >
            </div >
        </div >
    )
}
