import { Tarification } from "../../common/models/tarification";

export default function ConfigTarifItem(props: {tarif:Tarification,onOpenDlgDelete:any,onOpenDlgEdit:any}) {
   

    const openDlgDelete = async () => {
        props.onOpenDlgDelete(props.tarif);
    }

    const openDlgEdit = async () => {
        props.onOpenDlgEdit(props.tarif);
    }
   

    return (
        <div className="d-flex line_box justify-content-between">
       <div  >                
          <p>{props.tarif.Libelle}</p>
        </div>
        <div className="col_action">
            <i className="pointer text-danger margright" onClick={openDlgDelete} title="Supprimer le tarif"> <i className="fa-solid fa-remove" /></i>    
            <i className="pointer text-primary" onClick={openDlgEdit} title="Modifier le tarif"> <i className="fa-solid fa-edit"/></i>
        </div>
      </div>);

};

