import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Proprio } from "../models/proprio";
import { dataService } from "../../services/Services";
import { Pension } from "../models/pension";
import { Pet } from "../models/pet";
import { Tarification } from "../models/tarification";
import { Periode } from "../models/periode";
import { getDateStr2FromTimeStamp, round2Digit } from "../../utils/Tools";
import { Invoice } from "../models/invoice";
import { CreateContract } from "../../utils/GenContractPdf";


export default function DlgContratGen(props: { proprioInfo: Proprio, onClose: any, onValid: any }) {


    const {  handleSubmit } = useForm();

    const [infoPension, setInfoPension] = useState(new Pension());
    const [listPet, setListPet] = useState<Pet[]>([]);
    const [listTarif, setListTarif] = useState<Tarification[]>([]);
    const [listPeriode, setListPeriode] = useState<Periode[]>([]);
    const [listPeriodeSelected, setListPeriodeSelected] = useState<number[]>([]);
    const [invoiceInfo, setInvoiceInfo] = useState(new Invoice());
    const [refreshId, setRefreshId] = useState(0);
    const [montantSansRemise, setMontantSansRemise] = useState(0);


    useEffect(() => {

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.proprioInfo]);

    const loadData = async () => {
        invoiceInfo.ListPets = [];
        invoiceInfo.NomProprietaire = props.proprioInfo.Nom;
        invoiceInfo.Email = props.proprioInfo.Email;
        invoiceInfo.Telephone = props.proprioInfo.NumPortable;
        invoiceInfo.Adresse = props.proprioInfo.Adresse;
        invoiceInfo.Date = new Date().getTime() / 1000;
        invoiceInfo.Numero = 1;


        var restPension = await dataService.Configuration.getInfoPension();
        var restPet = await dataService.Pets.listPetForProprio(props.proprioInfo.Id);
        var restTarif = await dataService.Tarifications.listTarification(-2);
        var restPeriode = await dataService.Periodes.listPeriodeInvoice(props.proprioInfo.Id);

        setInfoPension(restPension.data);
        setListPet(restPet.data);

        let listTarifTmp = restTarif.data.filter((x: Tarification) => x.Categorie >= 0);

        let listPeriodeTmp = new Array<Periode>();
        for (var i = 0; i < restPeriode.data.length; i++) {
            let periode = restPeriode.data[i];
            var tarif = listTarifTmp.find((x: Tarification) => x.Id === periode.TarifId);
            if (tarif !== undefined) {
                periode.Montant = tarif.Montant;
                periode.Acompte = tarif.Tva;
                periode.MontantDemi = tarif.MontantDemi;
            }
            listPeriodeTmp.push(periode);
        }
        setListPeriode(listPeriodeTmp);
        setListTarif(listTarifTmp);
    }

    const changeSelectionPeriode = (periodeId: number) => {
        let listPeriodeSelectedTmp = listPeriodeSelected;
        var indexElt = listPeriodeSelected.indexOf(periodeId);
        if (indexElt > -1)
            listPeriodeSelectedTmp.splice(listPeriodeSelectedTmp.indexOf(periodeId), 1);
        else
            listPeriodeSelectedTmp.push(periodeId);

        setListPeriodeSelected(listPeriodeSelectedTmp);
        let totalContrat = 0;

        invoiceInfo.ListPets =[];
        invoiceInfo.Duree = 0;
        invoiceInfo.NbJour = 0;
        invoiceInfo.NbDemi = 0;
        invoiceInfo.DateDeb = 0;
        invoiceInfo.DateFin = 0;

        listPeriode.forEach((element: Periode) => {
            if (listPeriodeSelectedTmp.indexOf(element.Id) > -1) {
                var tarif = listTarif.find(x => x.Id === element.TarifId);
                if (tarif !== undefined) {
                    totalContrat += (tarif.Montant * (1 + tarif.Tva / 100)) * element.NbJour + (tarif.MontantDemi * (1 + tarif.Tva / 100)) * element.NbDemi;
                    element.Montant = tarif.Montant;
                    element.MontantDemi = tarif.MontantDemi;
                }
                var pet = listPet.find(x => x.Id === element.IdAnimal);
                if (pet !== undefined)
                    invoiceInfo.ListPets.push(pet);
                invoiceInfo.Duree = element.NbJour + (element.NbDemi * 0.5);
                invoiceInfo.NbJour = element.NbJour;
                invoiceInfo.NbDemi = element.NbDemi;
                invoiceInfo.DateDeb = element.DateDeb;
                invoiceInfo.DateFin = element.DateFin;


            }
        });
        setMontantSansRemise(totalContrat);
        invoiceInfo.Montant = round2Digit(totalContrat * (1 - invoiceInfo.Remise / 100));
        setInvoiceInfo(invoiceInfo);
        setRefreshId(refreshId + 1);
        //  this.totalRemise = Math.round((this.totalContrat*(1-this.remise/100))*100)/100 ;
    }    

    const updateRemise = async (event: any) => {

        invoiceInfo.Remise = event.target.value;
        invoiceInfo.Montant =round2Digit( montantSansRemise * (1 - event.target.value / 100));
        setInvoiceInfo(invoiceInfo);
        setRefreshId(refreshId + 1);
    }


    const updateMontantTotal = async (event: any) => {
        setMontantSansRemise(event.target.value);
        invoiceInfo.Montant = round2Digit(event.target.value * (1 - invoiceInfo.Remise / 100));
        setInvoiceInfo(invoiceInfo);
        setRefreshId(refreshId + 1);
    }

    const onSubmit = (data: any) => {

        // var box = data;
        // box.Id = props.box.Id;
        // box.CategorieAnimal = props.box.CategorieAnimal;
        // box.IsDelete=false;
        // props.onValid(box);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    return (

        <Dialog
            title={`Génération d'un contrat pour ${props.proprioInfo.Nom}`}
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <CreateContract infoPension={infoPension} invoiceInfo={invoiceInfo} />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
            <div className="dlg_contrat_gen">
                <div className="form-group row">
                    <label className="col-12 col-form-label">Choix des périodes à mettre dans le contrat</label>
                </div>
                {listPeriode && listPeriode.map((periode, key) =>
                    <div key={key} className="form-group row margleft" >
                        <div className="col-1">
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" name="chk_check" onChange={(event) => changeSelectionPeriode(periode.Id)} />
                            </div>
                        </div>
                        <div className="col-3">{periode.Nom}</div>
                        <div className="col-3">
                            <div>
                                <span>Du </span>
                                <strong>{getDateStr2FromTimeStamp(periode.DateDeb)}</strong>
                                <br />
                                <span> au </span>
                                <strong>{getDateStr2FromTimeStamp(periode.DateFin)}</strong>
                            </div>
                        </div>
                        <div className="col-2">
                            {periode.NbJour} jour(s)
                            {periode.NbDemi > 0 && <span ><br /> {periode.NbDemi} demi jour(s)</span>}
                        </div>
                        <div className="col-3">
                            {periode.Montant} €/jour {periode.Acompte > 0 && <span > (tva {periode.Acompte}%)</span>}
                            {periode.MontantDemi > 0 && <span ><br /> {periode.MontantDemi} €/demi jour(s)</span>}
                        </div>
                    </div>)}
                <div className="form-group row">
                    <label className="col-3 col-form-label">Remise sur séjour</label>
                    <div className="col-2">
                        <input placeholder="Remise du séjour" type="text" className="form-control" onChange={(e) => updateRemise(e)} defaultValue={invoiceInfo.Remise} />
                    </div>
                    <label className="col-3 col-form-label">%</label>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Montant total</label>
                    <div className="col-2">
                        <input placeholder="Montant du séjour" type="text" className="form-control" onChange={(e) => updateMontantTotal(e)} value={montantSansRemise} />
                    </div>
                    <label className="col-3 col-form-label"> €</label>
                </div>
                <div className="form-group row">
                    <label className="col-12 col-form-label">Montant total avec remise <strong> {invoiceInfo.Montant}</strong> €</label>

                </div>
            </div>


        </Dialog >
    );
}
