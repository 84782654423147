
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { dataService } from '../services/Services';
import { useEffect } from 'react';

function Login() {

  const { register, handleSubmit, formState: { errors} } = useForm();

  
  useEffect(() => {
    localStorage.removeItem('userpln');


}, []);

  const onSubmit = (data: any) => {   
    dataService.Users.getUserInfo(data.login,data.password).then((response) => { loadInfoUser(response.data); });

  }

  const loadInfoUser = async (infoUser :any) => {
    if (infoUser && infoUser.UserId>0) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('userpln', JSON.stringify(infoUser));
      navigate('/home')
     // this.currentUserSubject.next(user);
    }
   
   }

  // const handleSubmit2 = (event: any) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {

  //     login();
  //   }

  //   setValidated(true);
  // };

  const navigate = useNavigate()

  // const login = () => {
  //   localStorage.setItem('user', 'test')
  //   navigate('/home')
  // }

  return (
    <div className="login">
      <div className="block-center mt-4 wd-xl">

        <div className="card card-flat">
          <div className="card-header text-center card_login">
            <a href="http://planimalin.fr" target="_blank" rel="noreferrer">
              <img className="block-center rounded logo_login" src='./img/planimalin_RGB_short.svg' alt="planimalin" />
            </a>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row'>
                <div className="form-group">
                  <div className="input-group ">
                    <input className="form-control border-end-0" type="text" placeholder="Nom d'utilisateur"  {...register("login", { required: true })} />
                    <span className="input-group-text bg-transparent border-start-0">
                       <i className="fa-solid fa-user" />
                    </span>
                  </div>
                  {errors.login && <div className="text-danger" >Ce champ est obligatoire</div>}
                </div>
              </div>
              <div className='row'>
                <div className="form-group">
                  <div className="input-group ">
                    <input className="form-control border-end-0" type="password" placeholder="Password"  {...register("password", { required: true })} />

                    <span className="input-group-text bg-transparent border-start-0">
                       <i className="fa-solid fa-lock" />
                    </span>
                  </div>
                  {errors.password && <div className="text-danger" >Ce champ est obligatoire</div>}
                </div>
              </div>
              <div className='d-grid gap-2'>
                <button className="btn btn-block btn-primary " type="submit">Se connecter</button>
              </div>
            </form>

          </div>
        </div>

      </div>
    </div>


  )
}

export default Login;