import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Pension } from "../models/pension";


export default function DlgPensionEdit(props: { pension: Pension, onClose: any, onValid: any }) {


    const { register,  handleSubmit } = useForm();
    const [modeTarification, setModeTarification] = useState(0);

    useEffect(() => {

        setModeTarification(props.pension.ModeTarification);

    }, [props.pension]);

    const onSubmit = (data: any) => {

        var pension = data;
        pension.Id = props.pension.Id;
        pension.ModeTarification = modeTarification;
        props.onValid(pension);
    }
    const onChangeModeTarification = (event: any) => {
        setModeTarification(event.target.value);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    return (
        <Dialog
            title="Information sur la pension"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
            <div className="dlg_pension_edit">
                <div className="form-group row">
                    <label className="col-3 col-form-label">Nom</label>
                    <div className="col-9">
                        <input placeholder="Nom" type="text" className="form-control"   {...register("Nom", { required: false })} defaultValue={props.pension.Nom} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Adresse</label>
                    <div className="col-9">
                        <textarea placeholder="Adresse" className="form-control note-editor" rows={2}   {...register("Adresse", { required: false })} defaultValue={props.pension.Adresse} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Code postal</label>
                    <div className="col-3">
                        <input placeholder="Code postal" className="form-control" {...register("CodePostal", { required: false })} defaultValue={props.pension.CodePostal} />
                    </div>
                    <label className="col-1 col-form-label">Ville</label>
                    <div className="col-5">
                        <input placeholder="Ville" className="form-control "  {...register("Ville", { required: false })} defaultValue={props.pension.Ville} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Fixe</label>
                    <div className="col-3">
                        <input placeholder="Téléphone" type="text" className="form-control"  {...register("NumTel", { required: false })} defaultValue={props.pension.NumTel} />
                    </div>
                    <label className="col-1 col-form-label">Portable</label>
                    <div className="col-5">
                        <input placeholder="Portable" type="text" className="form-control"  {...register("NumPortable", { required: false })} defaultValue={props.pension.NumPortable} />

                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Email</label>
                    <div className="col-9">
                        <input placeholder="Email" type="text" className="form-control"  {...register("Email", { required: false })} defaultValue={props.pension.Email} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Numéro Siret</label>
                    <div className="col-9">
                        <input placeholder="Numéro siret" type="text" className="form-control"  {...register("Siret", { required: false })} defaultValue={props.pension.Siret} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Représenté par</label>
                    <div className="col-9">
                        <input placeholder="Représenté par" type="text" className="form-control"  {...register("RepresentePar", { required: false })} defaultValue={props.pension.RepresentePar} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Mentions légales facture</label>
                    <div className="col-9">
                        <textarea placeholder="Mentions légales" className="form-control  note-editor" rows={4}  {...register("MentionsLegales", { required: false })} defaultValue={props.pension.MentionsLegales} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Mentions légales contrat</label>
                    <div className="col-9">
                        <textarea placeholder="Mentions légales du contrat" className="form-control  note-editor" rows={4}   {...register("MentionsLegalesContrat", { required: false })} defaultValue={props.pension.MentionsLegalesContrat} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Alerte vaccin (jours avant)</label>
                    <div className="col-9">
                        <input placeholder="Nombre de jours avant" type="number" className="form-control"  {...register("VaccinAlert", { required: false })} defaultValue={props.pension.VaccinAlert} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Poucentage d'acompte</label>
                    <div className="col-9">
                        <input placeholder="Poucentage acompte" type="number" className="form-control"  {...register("PercentAcompte", { required: false })} defaultValue={props.pension.PercentAcompte} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Mode de tarification</label>
                    <div className="col-4">
                        <select className="form-control"  onChange={onChangeModeTarification}  defaultValue={props.pension.ModeTarification}>
                            <option value="1" >Nuit</option>
                            <option value="2" >Journée et demi journée</option>
                            <option value="3" >Journée</option>
                        </select>
                    </div>
                    {modeTarification === 2 &&   <label className="col-3 col-form-label">Heure fin matinée</label>}
                    {modeTarification === 2 &&   <div className="col-2">
                        <select className="form-control"  {...register("HeureFinMatin", { required: false })} defaultValue={props.pension.HeureFinMatin}>
                            <option value="0" >Minuit</option>
                            <option value="1" >1H</option>
                            <option value="2" >2H</option>
                            <option value="3" >3H</option>
                            <option value="4" >4H</option>
                            <option value="5" >5H</option>
                            <option value="6" >6H</option>
                            <option value="7" >7H</option>
                            <option value="8" >8H</option>
                            <option value="9" >9H</option>
                            <option value="10" >10H</option>
                            <option value="11" >11H</option>
                            <option value="12" >12H</option>
                            <option value="13"  >13H</option>
                            <option value="14" >14H</option>
                            <option value="15" >15H</option>
                            <option value="16" >16H</option>
                            <option value="17" >17H</option>
                            <option value="18" >18H</option>
                            <option value="19" >19H</option>
                            <option value="20" >20H</option>
                            <option value="21" >21H</option>
                            <option value="22" >22H</option>
                            <option value="23" >23H</option>
                        </select>
                    </div>}
                </div>
               
            </div >


        </Dialog >
    );
}
