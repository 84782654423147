import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Pet } from "../models/pet";
import { dataService } from "../../services/Services";
import { Relation } from "../models/relation";


export default function DlgPetRelation(props: { petType: number, petId: number, onClose: any, onValid: any }) {

   
    const [listPet, setListPet] = useState<Pet[]>([]);
    const [filterPet, setFilterPet] = useState("");
    const [refreshId, setRefreshId] = useState(0);
    const {  handleSubmit } = useForm();


    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        var restPet = await dataService.Pets.listPet(props.petType);
        var restRelation = await dataService.Pets.listRelation(props.petId, 0);

        restPet.data.forEach((animal: Pet) => {
            if (animal.Id !== props.petId) {
                let relation = restRelation.data.find((x: Relation) => x.IdAnimal1 === animal.Id || x.IdAnimal2 === animal.Id);
                if (relation === null) {
                    animal.Relation = 0;// listPetDispo.push(animal);
                } else {
                    if (relation.TypeRelation === 1)
                        animal.Relation = 1;//;listRelationAmical.push(relation)
                    else if (relation.TypeRelation === 2)
                        animal.Relation = 2;//listRelationRivaux.push(relation)
                }
            }
            //animal.Relation = true;
        });
        setListPet(restPet.data);


    }


    const filterChange = (event: any) => {
        setFilterPet(event.target.value);
    }

    const onSubmit = (data: any) => {

        var pet = data;
        //   pet.Id = props.infoCompl.Id;
        props.onValid(pet);
    }

    const removeRelation = async (typerelation: number, idSelectAnimal: number) => {
        dataService.Pets.deleteRelation(typerelation, props.petId, idSelectAnimal).then(result => 
            {
                if(result.data.CodeError===0){
                    var petFin = listPet.find(x=>x.Id===idSelectAnimal);
                    if(petFin)
                    petFin.Relation=0;
                    setRefreshId(refreshId+1);
                }

            }
            );
    }

    const addToFriend = async (idSelectAnimal: number) => {
        dataService.Pets.insertRelation(1, props.petId, idSelectAnimal).then(result => 
            {
                if(result.data.CodeError===0){
                    var petFin = listPet.find(x=>x.Id===idSelectAnimal);
                    if(petFin)
                    petFin.Relation=1;
                    setRefreshId(refreshId+1);
                }

            }
            );
    }
    const addToUnFriend = async (idSelectAnimal: number) => {
        dataService.Pets.insertRelation(2, props.petId, idSelectAnimal).then(result => 
            {
                if(result.data.CodeError===0){
                    var petFin = listPet.find(x=>x.Id===idSelectAnimal);
                    if(petFin)
                    petFin.Relation=2;
                    setRefreshId(refreshId+1);
                }

            }
            );
    }



    const onCloseDlg = async () => {
        props.onClose();
    }





    return (
        <Dialog
            title="Relation avec les congénères"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Fermer</div>
                </div>
            }>

            <div className="dlg_pet_relation">

                <div className="form-group row">
                    <div className="col-12">
                        <input placeholder="Filtrer le nom de l'animal" name="filterPet" type="text" className="form-control" value={filterPet} onChange={filterChange} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-4">
                        <div className="text-center text-bold">Liste des animaux</div>
                        <div className="table-responsive limit-height">
                            <table className="table">
                                <tbody>

                                    {listPet && listPet.map(animal => (
                                        animal.Relation === 0 &&(filterPet==="" || animal.Nom.toLowerCase().search(filterPet.toLowerCase())!==-1) && <tr key={animal.Id} >
                                            <td> {animal.Nom} ({animal.NomProprietaire})</td>
                                            <td className='div_action'>
                                                <div className="d-flex">
                                                    <div className="pointer btn_action text-success margright" onClick={(event) => addToFriend(animal.Id)}>  <i className="fa-solid fa-face-laugh" /></div>
                                                    <div className="pointer btn_action text-danger" onClick={(event) => addToUnFriend(animal.Id)}> <i className="fa-solid fa-face-angry" /></div>
                                                </div>
                                            </td>
                                        </tr>)
                                    )

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="text-center text-bold">Amis</div>
                        <div className="table-responsive limit-height">
                            <table className="table">
                                <tbody>
                                    {listPet && listPet.map(animal => (
                                        animal.Relation === 1 && <tr key={animal.Id} >
                                            <td> {animal.Nom} ({animal.NomProprietaire})</td>
                                            <td className='div_action'>
                                                <div className="pointer btn_action text-danger" onClick={(event) => removeRelation(1, animal.Id)}> <i className="fa-solid fa-xmark" /></div>
                                            </td>
                                        </tr>))

                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="col-4">
                        <div className="text-center text-bold">Rivaux</div>
                        <div className="table-responsive limit-height">
                            <table className="table">
                                <tbody>


                                    {listPet && listPet.map(animal => (
                                        animal.Relation === 2 && <tr key={animal.Id} >
                                            <td> {animal.Nom} ({animal.NomProprietaire})</td>
                                            <td className='div_action'>

                                                <div className="pointer btn_action text-danger" onClick={(event) => removeRelation(2, animal.Id)}> <i className="fa-solid fa-xmark" /></div>

                                            </td>
                                        </tr>))

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="ligne">
                    <div id="editEtatmessageError" className="text-danger"></div>
                </div>
            </div >

        </Dialog >
    );
}
