import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Periode } from "../../common/models/periode";
import { getDateTimeStrFromTimeStamp } from "../../utils/Tools";
import ReactTooltip from 'react-tooltip';

function ProprioPeriodeItem(props: any) {
    const [periode, setPeriode] = useState(new Periode());
    const [tooltip, setTooltip] = useState("");



    useEffect(() => {
        setPeriode(props.periodeItem);
        var info = "<div>Créé le " + getDateTimeStrFromTimeStamp(props.periodeItem.DateCreation) + "</div>";
        if (props.periodeItem.Etat > 0)
            info += "<div >Devis</div> ";
        if (props.periodeItem.DateDevis > 0)
            info += "<div >Envoyé le " + getDateTimeStrFromTimeStamp(props.periodeItem.DateDevis) + "</div> ";
        if (props.periodeItem.DateValidation > 0 && props.periodeItem.Etat === 2)
            info += "<div >Validé le " + getDateTimeStrFromTimeStamp(props.periodeItem.DateValidation) + "</div> ";
        if (props.periodeItem.Etat === 3)
            info += "<div>Validé sans devis le " + getDateTimeStrFromTimeStamp(props.periodeItem.DateValidation) + "</div> ";
        setTooltip(info);

    }, [props.periodeItem]);

    const openDlgEdit = async () => {
        props.onEditPeriode(periode);
    }

    const openDlgActionDevis = async (statut: number) => {

        props.onEditState(periode, statut);
    }

    return (

        <tr>
            <td className="text-center">
                <div data-tip={tooltip} data-for={'tt_etat' + periode.Id}>
                    {periode.Etat === 0 && <span className="text-danger" > <i className="fa-solid fa-exclamation-triangle" /></span>}
                    {periode.Etat === 1 && <span className="text-warning" > <i className="fa-solid fa-paper-plane" /></span>}
                    {(periode.Etat === 2 || periode.Etat === 3) && <span className="text-success"> <i className="fa-solid fa-calendar-check" /></span>}
                </div>
                <ReactTooltip id={'tt_etat' + periode.Id}
                    html={true}
                    effect='solid'
                    place={'right'}
                     />
            </td>
            <td>
                <NavLink to={'/pet/' + periode.TypeAnimal + "/" + periode.IdAnimal}>
                    <span className='pointerBold' >{periode.Nom}</span>
                </NavLink>
            </td>
            <td>
                <div>
                    <span>Du </span>
                    <strong>{getDateTimeStrFromTimeStamp(periode.DateDeb)}</strong>

                    <span> au </span>
                    <strong>{getDateTimeStrFromTimeStamp(periode.DateFin)}</strong>
                </div>

            </td>
            <td>
                <span>Durée </span>
                {props.config.ModeTarification === 1 && <strong > {periode.NbJour} nuit(s)</strong>}
                {props.config.ModeTarification === 2 && <strong  > {periode.NbJour} jour(s)</strong >}
                {periode.NbDemi > 0 && <span  > et < strong > {periode.NbDemi} demi-journée(s)</strong ></span >}
                {props.config.ModeTarification === 3 && <strong  > {periode.NbJour} jour(s)</strong >}
            </td >
            <td>
                <span>Dans </span>
                <strong> {periode.NomBox}</strong>
            </td>
            {periode.Montant > 0 && <td  >
                <strong> {periode.Montant}</strong>
                <span>€</span>
                {periode.Acompte > 0 && <span  > (Acompte de < strong > {periode.Acompte}</strong >)</span >}
            </td >}
            <td className='col_action'>
                <div className='d-flex justify-content-between'>
                    <div className="btn_action text-primary pointer" onClick={openDlgEdit} title="Modifier la période"> <i className="fa-solid fa-edit" /></div>
                    {periode.Etat < 1 && <div onClick={() => openDlgActionDevis(1)} className="btn_action  text-warning pointer" title="Indiquer que le devis a été envoyé"> <i className="fa-solid fa-paper-plane" /></div>}
                    {periode.Etat < 2 && <div onClick={() => openDlgActionDevis(2)} className="btn_action text-success pointer" title="Indiquer que la réservation a été validé avec devis" >  <i className="fa-solid fa-calendar-check" /></div >}
                    {periode.Etat < 2 && <div onClick={() => openDlgActionDevis(3)} className="btn_action text-success pointer" title="Indiquer que  la réservation a été validé sans devis" >  <i className="fa-solid fa-check" /></div >}
                </div >
            </td >
        </tr>
    );
}
export default ProprioPeriodeItem;