import { useContext,   } from "react";
import { ConfigContextType } from "../../common/models/config";
import { ConfigContext } from "../../context/ConfigContext";
import ConfigBox from './ConfigBox';
import ConfigPension from './ConfigPension';
import ConfigTarif from './ConfigTarif';
import ConfigVaccin from './ConfigVaccin';

export default function Configuration(props: any) {

  
  const { config } = useContext(ConfigContext) as ConfigContextType;
  
  return (
    <div className="container-fluid margtop configuration">
   
      <div className="row">
        <div className="col-12">
          <ConfigPension />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <ConfigBox />
        </div>
        <div className="col-4">
          <ConfigVaccin />
        </div>
        {config.ExistFacture && <div className="col-4" >
          <ConfigTarif />
        </div>}
      </div>
    </div>);
}
