
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Box } from "../models/box";


export default function DlgBoxEdit(props: { box: Box, onClose: any, onValid: any }) {


    const { register,  handleSubmit  } = useForm();


    const onSubmit = (data: any) => {

        var box = data;
        box.Id = props.box.Id;
        box.CategorieAnimal = props.box.CategorieAnimal;
        box.IsDelete=false;
        props.onValid(box);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    return (
        <Dialog
            title="Information sur le box"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
                   <div className="dlg_box_edit">
            <div className="form-group row ">

                <label className="col-3 col-form-label">Nom du box</label>
                <div className="col-9">
                    <input placeholder="Nom" type="text" className="form-control" {...register("Nom", { required: false })} defaultValue={props.box.Nom} />
                </div>
            </div>
            </div>


        </Dialog >
    );
}
