
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { MinMaxDate } from "../common/models/min-max-date";
import moment from "moment";

export function renderImgAnimal(petCategorie: number) {
  var result = "";
  switch (petCategorie) {
    case 0:
      result = "/img/planimalin_cat.svg";
      break;
    case 1:
      result = "/img/planimalin_dog.svg";
      break;
    case 2:
      result = "/img/planimalin_nac.svg";
      break;
    default:
      result = "/img/planimalin_cat.svg";
      break;

  }
  return result;
}


export function renderInOut(typeMouvement: number) {
  var result = "";
  switch (typeMouvement) {
    case 0:
      result = "/img/planimalin_in_out.svg";
      break;
    case 1:
      result = "/img/planimalin_in.svg";
      break;
    case 2:
      result = "/img/planimalin_out.svg";
      break;
    default:
      result = "/img/planimalin_in_out.svg";
      break;
  }
  return result;
}


export function renderClassHour(typeMouvement: number) {
  var result = "";
  switch (typeMouvement) {
    case 0:
      result = "hoursES";
      break;
    case 1:
      result = "hoursE";
      break;
    case 2:
      result = "hoursS";
      break;
    default:
      result = "hoursES";
      break;

  }
  return result;
}

export function renderSexeAnimal(param: number) {
  var result = "";
  switch (param) {
    case 0:
      result = "/img/planimalin_male.svg";
      break;
    case 1:
      result = "/img/planimalin_female.svg";
      break;
    default:
      result = "/img/planimalin_male.svg";
      break;

  }
  return result;
}


export function extractDateFromTimeStamp(ts: number) {
  const dated = new Date(ts * 1000);

  return new Date(dated.getFullYear(), dated.getMonth(), dated.getDate(), 0, 0, 0).getTime() / 1000;
}

export function getDateFromTimeStamp(ts: number) {
  return new Date(ts * 1000);
}



export function dateIsMorning(ts: number) {
  const dated = new Date(ts * 1000);

  return dated.getHours() <= 12;
}

export function getTimeStrFromTimeStamp(ts: number) {
  const dated = new Date(ts * 1000);
  return ("0" + (dated.getHours())).slice(-2) + ":" + ("0" + (dated.getMinutes())).slice(-2) ;
}

export function getDateStrFileFromTimeStamp(ts: number) {
  const dated = new Date(ts * 1000);

  return dated.getFullYear() + ("0" + (dated.getMonth() + 1)).slice(-2) + ("0" + (dated.getDate())).slice(-2);// new Date(dated.getFullYear(), dated.getMonth() , dated.getDate(), 0, 0, 0).getTime()/1000;
}

export function getHeureFromTimeStamp(ts: number) {
  return ts - extractDateFromTimeStamp(ts);
}

export function getformatDate(selectedDate: any) {
  if (selectedDate !== undefined)
    return format(selectedDate, 'EEEE dd/MM/yyyy', { locale: fr });
  else
    return "";
}

export function getDateStrFromTimeStamp(ts: number) {
  const dated = new Date(ts * 1000);

  return ("0" + (dated.getDate())).slice(-2) + "/" + ("0" + (dated.getMonth() + 1)).slice(-2) + "/" + dated.getFullYear();// new Date(dated.getFullYear(), dated.getMonth() , dated.getDate(), 0, 0, 0).getTime()/1000;
}

export function getDateTimeStrFromTimeStamp(ts: number) {
 
  if (ts === Infinity || ts===-Infinity) {
   return  "-";
  } else {
    const dated = new Date(ts * 1000);
    return format(dated, 'dd/MM/yyyy HH:mm', { locale: fr });
  }
}

export function getDateStr2FromTimeStamp(ts: number) {
 
  if (ts === Infinity || ts===-Infinity) {
   return  "-";
  } else {
    const dated = new Date(ts * 1000);
    return format(dated, 'dd/MM/yyyy', { locale: fr });
  }
}

export function getMonthStrFromTimeStamp(ts: number) {
 
  if (ts === Infinity || ts===-Infinity) {
   return  "-";
  } else {
    const dated = new Date(ts * 1000);
    return format(dated, 'MMMM', { locale: fr });
  }  
}

export function getMinMaxWithMonthYear(month: number, year: number) {
  let minmax = new MinMaxDate();
  minmax.MinDate = moment([year, month - 1, 1, 0, 0, 0]).unix();
  minmax.MaxDate = moment([year, month - 1, 1, 23, 59, 59]).endOf('month').unix();  //(new Date(this.selectedYear, this.selectedMonth, 0, 23, 59, 59).getTime() / 1000);

  return minmax;
}

export function convertMonthStrToString(month: string) {
return convertMonthToString(parseInt(month))
}

export function convertMonthToString(month: number) {
  let monthStr = "";
  switch (month) {
    case 1:
      monthStr = "Janvier";
      break;
    case 2:
      monthStr = "Février";
      break;
    case 3:
      monthStr = "Mars";
      break;
    case 4:
      monthStr = "Avril";
      break;
    case 5:
      monthStr = "Mai";
      break;
    case 6:
      monthStr = "Juin";
      break;
    case 7:
      monthStr = "Juillet";
      break;
    case 8:
      monthStr = "Août";
      break;
    case 9:
      monthStr = "Septembre";
      break;
    case 10:
      monthStr = "Octobre";
      break;
    case 11:
      monthStr = "Novembre";
      break;
    case 12:
      monthStr = "Décembre";
      break;

  }

  return monthStr;
}

export function round2Digit(val:number){
  return Number.parseFloat((Math.round(val * 100) / 100.0).toFixed(2));
}


export function getPaimentLib(id: number) {
  var result = "";
  switch (id) {
    case 1:
      result = "Autres";
      break;
    case 2:
      result = "Chèque";
      break;
    case 3:
      result = "Carte bleue";
      break;
    case 4:
      result = "Espèces";
      break;
    case 5:
      result = "Virement";
      break;
    case 6:
      result = "Paypal";
      break;
  }
  return result;

}