import moment from 'moment';
import React, { useRef, useState } from 'react';
import DlgPeriodeEdit from '../../common/dialog/DlgPeriodeEdit';
import { Box } from '../../common/models/box';
import { BoxPeriode } from '../../common/models/box-periode';
import { InfoDayCalendar } from '../../common/models/info-day-calendar';
import { MinMaxDate } from '../../common/models/min-max-date';
import { Periode } from '../../common/models/periode';
import { dataService } from '../../services/Services';
import { convertMonthToString } from '../../utils/Tools';
import PlanningLine from './PlanningLine';

export default function PlanningCalendar(props: { typeAnimal: number,infoMinMaxDate:MinMaxDate }) {
  
    const [widthCalendar, setWidthCalendar] = useState(0);
    const [heightScheduler, setHeightScheduler] = useState(0);
    const [listMonth, setListMonth] = useState<InfoDayCalendar[]>([]);
    const [listDay, setListDay] = useState<InfoDayCalendar[]>([]);
    const [listWeekend, setListWeekend] = useState<InfoDayCalendar[]>([]);
    const [listBoxPeriode, setListBoxPeriode] = useState<BoxPeriode[]>([]);    
    const [modeVue, setModeVue] = useState("display");
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [selectedPeriode, setSelectedPeriode] = useState<Periode>(new Periode());    

    const widthColBox: number = 200;
    const refPlanning = useRef(null);



    React.useEffect(() => {

        loadData();
    }, [props]);

    const loadData = async () => {
        if(props.typeAnimal>=0 && props.infoMinMaxDate.MinDate>0 &&props.infoMinMaxDate.MinDate>0 ){
            let restbox = await dataService.Boxs.listBox(props.typeAnimal);
            let restPeriode = await dataService.Periodes.listMoveDate(props.typeAnimal, props.infoMinMaxDate.MinDate, props.infoMinMaxDate.MaxDate);
            buildPlanning(restPeriode.data,restbox.data);
        }
    }
    
    const openEditPeriode = async (editPeriode: Periode) => {
        setModeVue("edit"); 
        setSelectedPeriode(editPeriode);   
    }

    const onCloseDlg = async () => {   
        setModeVue("display");
    }
    
     
      const updatePeriode = async (periode:Periode) => {
        dataService.Periodes.savePeriode(periode).then((response) => { loadData(); });
        onCloseDlg();
      }
    
      const deletePeriode = async (idperiode:number) => {
        dataService.Periodes.deletePeriode(idperiode).then((response) => { loadData(); });
        onCloseDlg();
      }

    const buildPlanning = async (listPeriode: Periode[],listBox:Box[]) => {
        let dayInSecond = 60 * 60 * 24;
        let widthForOneDay = 0;

        //calcul du calendrier
        let listMonthTmp = [];
        let listDayTmp = [];
        let listWeekendTmp = [];

        let nbDay = Math.trunc((props.infoMinMaxDate.MaxDate - props.infoMinMaxDate.MinDate) / (dayInSecond)) + 1;
        if (nbDay > 31)
            nbDay = 31;

       // let widthForAllDay = ((refPlanning.current ? refPlanning.current['offsetWidth'] : 0) - 18) - widthColBox;
        let widthForAllDay = ((windowSize ? windowSize.innerWidth : 0) - 18-70) - widthColBox;
      //  console.log(windowSize);
        widthForOneDay = Math.trunc(widthForAllDay / nbDay);
        if(widthForOneDay<50)
        widthForOneDay=50;
        widthForAllDay = widthForOneDay * nbDay;
        setWidthCalendar(widthForOneDay * nbDay + widthColBox);
        let tailleByUnit = widthForAllDay / (props.infoMinMaxDate.MaxDate - props.infoMinMaxDate.MinDate);
        let percentByUnit = 100 / (props.infoMinMaxDate.MaxDate - props.infoMinMaxDate.MinDate);


        setHeightScheduler(document.documentElement.clientHeight - 195);//this.planning.nativeElement.offsetHeight - 110;


        var posleft = widthColBox;
        var posleftPercent = 0;
        var dateInc = props.infoMinMaxDate.MinDate;

        //calcul liste numéro de jours
        let currentDay = moment.unix(props.infoMinMaxDate.MinDate);
        let listNumDay = [];
        for (var d = 0; d < nbDay; d++) {

            let date = new InfoDayCalendar();

            date.Jour = currentDay.date();
            date.Month = currentDay.month();
            date.Year = currentDay.year();
            listNumDay.push(date);
            currentDay.add(1, 'days')
        }


        for (var nd = 0; nd < nbDay; nd++) {
            var info = listNumDay[nd];
            info.Width = widthForOneDay;
            info.Height = 52;
            listDayTmp.push(info);

            //calcul mois Info
            let findMonthInfo = listMonthTmp.find(x => x.Month === info.Month + 1 && x.Year === info.Year);
            if (findMonthInfo === undefined) {
                let infoMonth = new InfoDayCalendar();
                if (info.Jour === 1)
                    infoMonth.Jour = 1;
                else
                    infoMonth.Jour = 0;
                infoMonth.Month = info.Month + 1;
                infoMonth.Year = info.Year;
                infoMonth.Posleft = posleft;
                infoMonth.LeftPercent = posleftPercent;
                infoMonth.Width = widthForOneDay;
                infoMonth.NbDay = 1;
                listMonthTmp.push(infoMonth);
            } else {
                findMonthInfo.Width += widthForOneDay;
                findMonthInfo.NbDay += 1;
            }



            var day = (new Date((dateInc + 3600 * 12) * 1000));
            if (day.getDay() === 0 || day.getDay() === 6) {
                var infowk = new InfoDayCalendar();
                infowk.Posleft = posleft - widthColBox;
                infowk.Width = widthForOneDay;
                infowk.ClassDay = 'timeline_weekend';
                info.ClassDay = 'timeline_weekend';
                listWeekendTmp.push(infowk);
            }
           // var options = { year: 'numeric', month: 'long', day: 'numeric' };

            if (new Date().toLocaleDateString('fr-fr', { year: 'numeric', month: 'long', day: 'numeric' }) === day.toLocaleDateString('fr-fr', { year: 'numeric', month: 'long', day: 'numeric' })) {
                var infotd = new InfoDayCalendar();
                infotd.Posleft = posleft - widthColBox;
                infotd.Width = widthForOneDay;
                infotd.ClassDay = 'timeline_today';
                listWeekendTmp.push(infotd);
            }

            posleft += widthForOneDay;
            posleftPercent += widthForOneDay * 100 / widthForAllDay;
            dateInc += (60 * 60 * 24);
        }

        //calcul des périodes/box
        let listBoxPeriodeTmp = [];
        for (var i = 0; i < listBox.length; i++) {
            var boxPeriode: BoxPeriode = new BoxPeriode();
            boxPeriode.BoxInfo = listBox[i];
            boxPeriode.ListPeriode = listPeriode.filter(x => x.IdBox === listBox[i].Id);
            boxPeriode.Height = 52;
            var nbPeriodeMaxInSpace = 0;
            for (var j = 0; j < boxPeriode.ListPeriode.length; j++) {
                var periode = boxPeriode.ListPeriode[j];
             
             
                let debPer = (moment.unix(periode.DateDebPlan));
                let finPer = (moment.unix(periode.DateFinPlan));
                
                for (debPer=debPer.startOf('day'); debPer <= finPer; debPer.add(1,'days')) {
                    let dayfind =  listDayTmp.find(x=>x.Jour===debPer.date() && x.Month===debPer.month() && x.Year===debPer.year());
                    if(dayfind){
                      dayfind.NbPet++;                   
                    }
                }
            
                
                
                let diffTs = periode.DateFinPlan - periode.DateDebPlan;

                periode.Left = tailleByUnit * (periode.DateDebPlan - props.infoMinMaxDate.MinDate);//-(tailleByUnit*60*60);
                periode.Width = tailleByUnit * (diffTs);
                periode.LeftPercent = percentByUnit * (periode.DateDebPlan - props.infoMinMaxDate.MinDate);
                periode.WidthPercent = percentByUnit * (diffTs);
                periode.Top = 2;
                periode.Line = 0;

                var lineNotEmpty = [];
                for (var k = 0; k < j; k++) {
                    var periodePrevious = boxPeriode.ListPeriode[k];
                    if ((periodePrevious.Left <= periode.Left && periode.Left <= (periodePrevious.Left + periodePrevious.Width)) ||
                        (periodePrevious.Left <= (periode.Left + periode.Width) && (periode.Left + periode.Width) <= (periodePrevious.Left + periodePrevious.Width)) ||
                        (periode.Left <= periodePrevious.Left && periodePrevious.Left <= (periode.Left + periode.Width)) ||
                        (periode.Left <= (periodePrevious.Left + periodePrevious.Width) && (periodePrevious.Left + periodePrevious.Width) <= (periode.Left + periode.Width))
                    ) {

                        lineNotEmpty.push(periodePrevious.Line);
                    }
                }
                var myPos = -1;
                for (var l = 0; l < lineNotEmpty.length && myPos === -1; l++) {
                    if (l !== lineNotEmpty[l]) {
                        myPos = l;
                    }
                }
                if (myPos === -1)
                    myPos = lineNotEmpty.length > 0 ? lineNotEmpty.length : 0;

                if (myPos > 0) {
                    periode.Top = 2 + 52 * myPos;
                    periode.Line = myPos;
                }

                if (myPos > nbPeriodeMaxInSpace) {
                    nbPeriodeMaxInSpace = myPos;
                }


            }
            if (nbPeriodeMaxInSpace > 0)
                boxPeriode.Height = 52 + nbPeriodeMaxInSpace * 52;

            listBoxPeriodeTmp.push(boxPeriode);

        }

        
        setListMonth(listMonthTmp);
        setListDay(listDayTmp);
        setListBoxPeriode(listBoxPeriodeTmp);
        setListWeekend(listWeekendTmp);
    }


    return (
        <div className="row">
        <div className="col" id="divcontents" >
        <div  ref={refPlanning} id="scheduler_here" style={{  width: widthCalendar + 'px' , height: '100%', backgroundColor: 'white' }}>

            <div id="pln_header" style={{ height: '70px', display: 'flex', width: widthCalendar + 'px' }}>
                <div className="divtopleft" style={{ maxWidth: widthColBox + 'px', minWidth: widthColBox + 'px' }}></div>
                <div style={{ display: 'flex', flexDirection: 'column' }} className="calendarDay">
                    <div style={{ height: '30px', display: 'flex' }}>
                        {listMonth && listMonth.map((infoMonth,index) => (
                            <div key={index} className={`monthInfo ${infoMonth.Jour === 1 ? 'firstDay' : ''}`}
                                style={{ lineHeight: '30px', height: '30px', top: '0px', width: infoMonth.Width + 'px', left: infoMonth.Posleft + 'px' }}>
                                {convertMonthToString(infoMonth.Month) + ' ' + infoMonth.Year}</div>
                        ))}
                    </div>

                    <div style={{ display: 'flex' }}>
                      
                        {listDay && listDay.map((infoJour,index) => (
                            <div  key={index}
                                className={`dayItem ${infoJour.Jour === 1 ? 'firstDay' : ''}`}
                                style={{ lineHeight: '18px', height: '40px', top: '0px', width: infoJour.Width + 'px' }}>
                                <div>{infoJour.Jour}</div>
                                <div>({infoJour.NbPet})</div>
                            </div>))}

                    </div>
                </div>
            </div>

            <div style={{width: widthCalendar +25+ 'px' , height: heightScheduler + 'px', left: '0px', top: '61px' }}
                id="pln_content" className="pln_content">
                {/* pour chaque box  */}
                {listBoxPeriode && listBoxPeriode.map((boxPeriode) => (
                
                    <PlanningLine key={boxPeriode.BoxInfo.Id} boxPeriode={boxPeriode} infoMinMaxDate={props.infoMinMaxDate} listDay={listDay} listWeekend={listWeekend} selectedType={props.typeAnimal} openEditPeriode={openEditPeriode} />))}

            </div>
            {modeVue==="edit"&& <DlgPeriodeEdit onClose={onCloseDlg} onDelete={deletePeriode} onValid={updatePeriode} periode={selectedPeriode} />}
        </div> </div> </div>
    );

}
function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }