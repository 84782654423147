import React from 'react';
import MasterPage from './MasterPage'
import {Navigate} from 'react-router-dom'

const useAuth=()=>{
  const user=localStorage.getItem('userpln')
  if(user){
    return true
  } else {
    return false
  }
}

const  ProtectedRoute=(props:any) =>{

  const auth=useAuth()

  return (auth?<MasterPage/>: <Navigate to="login"/>)
}



export default ProtectedRoute;