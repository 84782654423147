import React, {  useState } from 'react';

import PlanningHeader from './PlanningHeader';

import PlanningCalendar from './PlanningCalendar';
import { MinMaxDate } from '../../common/models/min-max-date';
import moment from 'moment';
import { getMinMaxWithMonthYear } from '../../utils/Tools';

export default function Planning(props: any) {
    const [typePet, setTypePet] = useState(-1);
    const [infoMinMaxDate, setInfoMinMaxDate] = useState(new MinMaxDate())
 //   const componentRef = React.useRef(null);

    React.useEffect(() => {

        var today = moment();
        setInfoMinMaxDate(getMinMaxWithMonthYear(today.month() + 1,today.year()));

    }, [props]);


    const onChangeTypePet = async (newTypePet: number) => {
        setTypePet(newTypePet);
    };

    
    const onChangeInfoMinMaxDate = async (infoMinMaxDate: MinMaxDate) => {
        setInfoMinMaxDate(infoMinMaxDate);
    };

    return (
        <div className="container-fluid margtop planning">
         
                    <PlanningHeader onChangePeriode={onChangeInfoMinMaxDate} onChangeType={onChangeTypePet}/>
             

           
                    <PlanningCalendar typeAnimal={typePet} infoMinMaxDate={infoMinMaxDate} />                    
               
        </div>
    );

}
