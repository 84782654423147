import React, { useEffect, useState } from 'react';
import { dataService } from '../../services/Services';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { convertMonthStrToString,  getDateStr2FromTimeStamp,  } from '../../utils/Tools';

export default function DashbaordPercOccupBox(props: any) {

    const [data, setData] = useState<any>([]);//new InOutData());
    const [listYear, setListYear] = useState<number[]>([]);
    const [selectedYear, setSelectedYear] = useState(0);

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        var restMinMax = await dataService.Configuration.getMinMaxDateExploitation();


        var yearMin = new Date(restMinMax.data.MinDate * 1000).getFullYear();
        var yearMax = new Date(restMinMax.data.MaxDate * 1000).getFullYear();
        let listYear = new Array<number>();
        for (var i = yearMin; i <= (yearMax); i++)
            listYear.push(i);

        setListYear(listYear);

        var selectYear = 0;
        var today = new Date();
        if (yearMax >= today.getFullYear())
            selectYear = today.getFullYear();
        else
            selectYear = yearMax;


        loadGraph(selectYear);

    }

    const loadGraph = async (selectYear: number) => {

        var debut =  Date.UTC(selectYear, 0, 1);//.getTime();
        var fin =  Date.UTC(selectYear, 11, 31, 23, 59, 59);//.getTime();
        var restComparatif = await dataService.Statistique.getStatYearByCategorie(debut, fin);

        //var color = ['#DB7464', '#4DB6AC', '#FCC576', '#2E86C1', '#D4AC0D', '#7D3C98']
        var series = [];
        //for (var i = 0; i < restComparatif.data.listSerie.length; i++) {
        for (var j = 0; j < restComparatif.data.listSerie[0].data.length; j++) {
            series.push(
                {
                    name: getDateStr2FromTimeStamp(restComparatif.data.listSerie[0].data[j][0]/1000),
                    chats: restComparatif.data.listSerie[0].data[j][1],
                    chiens: restComparatif.data.listSerie[1].data[j][1],
                    nacs: restComparatif.data.listSerie[2].data[j][1],

                }
            )
        }
        //}

        setData(series);

        setSelectedYear(selectYear);

    }
    const onSelectedYearChange = (event: any) => {
        loadGraph(event.target.value);
    }
    const CustomizedAxisTick=  (props: any) => {
        const { x, y, payload } = props;
      if(typeof payload.value  === 'string' || payload.value  instanceof String )
        return (
          
          <g transform={`translate(${x},${y})`}>
            <text
              x={0}
              y={0}
              dy={16}
              textAnchor="end"
              fill="#666"
              transform="rotate(-15)"
            >
              { convertMonthStrToString(payload.value.substring(3, 5))}
            </text>
          </g>
        );
        else
        return (<div></div>);
      };

    return (
        <div className="card  b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Taux d'occupation des box</h3>
                </div>
                <div className="card-body" >
                    <div style={{ width: "100%", height: "300px" }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid  />
                                <XAxis  dataKey = 'name'
                                    tick={<CustomizedAxisTick />}
                                    interval={30}
                               />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="chats" stroke="#DB7464" dot={false}/>
                                <Line type="monotone" dataKey="chiens" stroke="#4DB6AC" dot={false} />
                                <Line type="monotone" dataKey="nacs" stroke="#FCC576" dot={false}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <hr className="d-print-none" />

                    <div className="card-action clearfix">
                        <select className="form-control" onChange={(event) => onSelectedYearChange(event)} value={selectedYear}>
                            {
                                listYear && listYear.map((year) =>
                                    <option key={year} value={year}>{year}</option>
                                )}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );

    

}
