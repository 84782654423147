import React, { useState } from 'react';
import { dataService } from '../services/Services';
import Select from 'react-select';

export function SelectProprio(props: { defaultProprioId: number; onSelect: any; }) {

    const { onSelect, defaultProprioId } = props;

    const [selectedProprio, setSelectedProprio] = useState<any>(null);
    const [listProprio, setListProprio] = useState<any[]>([]);

    React.useEffect(() => {
        dataService.Proprios.listProprioEntete().then((response) => { setListProprio(response.data) });
    }, []);


    React.useEffect(() => {        
        if (listProprio.length > 0) {
            var proprio = listProprio.find(x => x.Id === defaultProprioId);
            if (proprio !== undefined) {
                setSelectedProprio(proprio);
            } else {
                setSelectedProprio(listProprio[0]);
                onSelect(listProprio[0].Id)
            }         
        }
      
      
    }, [listProprio]);
    
    const handleChange = async (value: any) => {        
        if (value !== undefined) {
            onSelect(value.Id);
            setSelectedProprio(value);
        }
    };
  
    if (selectedProprio)
        return (
            <div>               
                <Select classNamePrefix="react-select-header"
                getOptionValue={(option) => `${option.Id}`}
                    getOptionLabel={(option: any) => `${option.Nom}`}
                    value={selectedProprio}
                    onChange={handleChange}
                    options={listProprio}
                />
            </div>
        );
    else
        return (<div>Chargement ...</div>)
}
