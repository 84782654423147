import React from 'react';
import {  useParams } from 'react-router-dom';
import { BoxPeriode } from '../../common/models/box-periode';
import { InfoDayCalendar } from '../../common/models/info-day-calendar';
import { MinMaxDate } from '../../common/models/min-max-date';
import { Periode } from '../../common/models/periode';
import PlanningItem from './PlanningItem';

export default function PlanningLine(props: { boxPeriode: BoxPeriode, infoMinMaxDate: MinMaxDate, listDay: InfoDayCalendar[], listWeekend: InfoDayCalendar[], selectedType: number,openEditPeriode:any }) {
   
    const params = useParams()
    const widthColBox =200;
   
    React.useEffect(() => {

    }, [params]);


    const openDlgEdit = async () => {
        let newPeriode = new Periode();
      
        newPeriode.Id= 0;
        newPeriode.TypeAnimal= props.selectedType;  
        newPeriode.IdBox=props.boxPeriode.BoxInfo.Id;
        var day=new Date();
    
        if(props.infoMinMaxDate.MinDate>(day.getTime()/1000))
          day = new Date(props.infoMinMaxDate.MinDate*1000);
          if(props.infoMinMaxDate.MaxDate<(day.getTime()/1000))
          day = new Date(props.infoMinMaxDate.MaxDate*1000);
    
        newPeriode.DateDeb = (new Date(day.getFullYear(),day.getMonth(),day.getDate(),8,0,0)).getTime()/1000;    
        newPeriode.DateFin = (new Date(day.getFullYear(),day.getMonth(),day.getDate(),18,0,0)).getTime()/1000;
        newPeriode.Montant=0;
        newPeriode.Montant=0;
        newPeriode.Acompte=0;
        props.openEditPeriode(newPeriode);
    }
    const openDlgEditDay = async (infoJour: InfoDayCalendar) => {
        let newPeriode = new Periode();
        newPeriode.Id= 0;
        newPeriode.TypeAnimal= props.selectedType;  
        newPeriode.IdBox=props.boxPeriode.BoxInfo.Id;
        newPeriode.DateDeb = (new Date(infoJour.Year,infoJour.Month,infoJour.Jour,8,0,0)).getTime()/1000;    
        newPeriode.DateFin = (new Date(infoJour.Year,infoJour.Month,infoJour.Jour,18,0,0)).getTime()/1000;
        newPeriode.Montant=0;
        newPeriode.Acompte=0;
    
        props.openEditPeriode(newPeriode);
    }


    return (
        <div style={{ height: props.boxPeriode.Height + 'px', display: 'flex' }}  >
            {/* nom du box  */}
            <div className="firstColonne d-flex justify-content-between" style={{ height: props.boxPeriode.Height + 'px', lineHeight: props.boxPeriode.Height + 'px', maxWidth: widthColBox + 'px', minWidth: widthColBox + 'px' }} >
                <div>{props.boxPeriode.BoxInfo.Nom} ({props.boxPeriode.ListPeriode.length})</div>
                <div className="btn btn-primary btn-action" onClick={openDlgEdit} >+</div>
            </div>
            {/* calendrier box */}
            <div className="calendarDay">
                <div style={{ height: props.boxPeriode.Height + 'px', width: '100%', position: 'relative' }}
                    className="line_day">

                    {/* info week end et autre  */}
                    {props.listWeekend && props.listWeekend.map((infoWeekend,index) => (
                        <div key={index} className={`special_day ${infoWeekend.ClassDay}`}
                            style={{ height: (props.boxPeriode.Height - 1) + 'px', width: infoWeekend.Width + 'px', left: infoWeekend.Posleft + 'px', top: '0px' }}>
                        </div>
                    ))}

                    {/* grille jour du mois */}
                    <div style={{ display: 'flex' }}>
                        {props.listDay && props.listDay.map((infoJour,index) => (
                            <div key={index}
                                onClick={(event) => openDlgEditDay(infoJour)}
                                className={`cell_day pointer ${infoJour.Jour === 1 ? 'firstDay' : ''}`}
                                style={{ width: infoJour.Width + 'px', height: props.boxPeriode.Height + 'px' }}></div>))}

                    </div>
                    {/*  periode du box */}
                    {props.boxPeriode.ListPeriode && props.boxPeriode.ListPeriode.map((periode) => (
                        <PlanningItem periode={periode} key={periode.Id} onEditPeriode={props.openEditPeriode} />
                    ))}

                </div >
            </div >
        </div >
    );

}
