export class Vaccine {
    Id: number=0;
    Libelle: string="";
    DateRealisation: number=0;
    CategorieAnimal: number=0;
    IsDelete : boolean=false;
    IsSelected: boolean=false;
}

export class VaccineAlert{
  
   Id: number=0;
   IdProprietaire: number=0;
   NomProprietaire : string="";
   AssoVaccinId:number=0;
   DateVaccin: number=0;
   LibelleVaccin: string="";
   Nom: string="";
   Type: number=0;
}