
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from '../pages/Login';
import Home from '../pages/Home/Home';
import ProtectedRoute from './ProtectedRoute';
import ProprioPage from '../pages/Proprio/ProprioPage';
import ProprioList from '../pages/Proprio/ProprioList'
import PetList from '../pages/Pet/PetList';
import PetPage from '../pages/Pet/PetPage';
import Planning from '../pages/Planning/Planning';
import Dashboard from '../pages/Dashboard/Dashboard';
import Configuration from '../pages/Configuration/Configuration';
import Record from '../pages/Record/Record';
import InvoiceList from '../pages/Invoice/InvoiceList';
import InvoiceDetail from '../pages/Invoice/InvoiceDetail';
import InvoiceEdit from '../pages/Invoice/InvoiceEdit';

const MainRoutes = () => (
    <Routes>
        <Route path="/" element={<ProtectedRoute />}>          
                <Route path="/" element={<Navigate replace to="home" />} />                
                <Route path="home" element={<Home />} />
                <Route path="proprio" element={<ProprioList />} />
                <Route path="proprio/:proprioId" element={<ProprioPage />} />
                <Route path="pet/:petType" element={<PetList />} />
                <Route path="pet/:petType/:petId" element={<PetPage/>} />
                <Route path="planning" element={<Planning/>} />
                <Route path="stats" element={<Dashboard/>} />
                <Route path="config" element={<Configuration/>} />
                <Route path="registre" element={<Record/>} />
                <Route path="invoice" element={<InvoiceList/>} />
                <Route path="invoice-detail/:id" element={<InvoiceDetail/>} />
                <Route path="invoice-edit/:id" element={<InvoiceEdit/>} />
               
      
        </Route>

        <Route path="login" element={<Login />}>
            
        </Route>
    </Routes>
)

export default MainRoutes