
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Vaccine } from "../models/vaccine";


export default function DlgVaccinEdit(props: { vaccin: Vaccine, onClose: any, onValid: any }) {


    const { register,  handleSubmit } = useForm();


    const onSubmit = (data: any) => {

        var box = data;
        box.Id = props.vaccin.Id;
        box.CategorieAnimal = props.vaccin.CategorieAnimal;
        box.IsDelete=false;
        props.onValid(box);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    return (
        <Dialog
            title="Information sur le vaccin"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
                   <div className="dlg_vaccin_edit">
            <div className="form-group row ">

                <label className="col-3 col-form-label">Nom du vaccin</label>
                <div className="col-9">
                    <input placeholder="Nom" type="text" className="form-control" {...register("Libelle", { required: false })} defaultValue={props.vaccin.Libelle} />
                </div>
            </div>
            </div>


        </Dialog >
    );
}
