
export class Pension {
    Adresse: string="";
    CodePostal : string="";
    Ville : string="";
    Email: string="";
    Id: number=0;
    Nom: string="";
    NumPortable: string="";
    NumTel: string="";
    Siret: string="";
    RepresentePar: string="";
    MentionsLegales:string="";
    MentionsLegalesContrat:string="";
    VaccinAlert:number=0;
    PercentAcompte:number=0;
    ModeTarification:number=0;
    HeureFinMatin:number=0;
}

export class ConfigPension{    
    ModeTarification:number=0;
}