export class Proprio {
    Adresse: string="";
    AutreContactName: string="";
    AutreContactTel: string="";
    Email: string="";
    Id: number=0;
    IsDCD: boolean=false;
    Nom: string="";
    NumPortable: string="";
    NumPortable2: string="";
    NumTel: string="";
    VetoAdresse: string="";
    VetoName: string="";
    VetoNumTel: string="";
    VetoNumTel2: string="";
    ConnuPar:string="";
    IsVisible: boolean=false;
}
